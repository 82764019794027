import React, { useState, useEffect } from "react";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import { Homepage } from "./components/homePage";
import { Navigation } from "./components/navigation";
import { BlogPage } from "./components/blogPage";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route
            path="/blog/:id"
            render={(props) => (
              <BlogPage {...props} data={landingPageData.Blogs} />
            )} />
        </Switch>
      </Router>
    </>

    // <div>
    //   <Navigation />
    //   <Header data={landingPageData.Header} />
    //   <Features data={landingPageData.Features} />
    //   <About data={landingPageData.About} />
    //   <Services data={landingPageData.Services} />
    //   <Blog data={landingPageData.Blogs} />
    //   {/* <Gallery data={landingPageData.Gallery} /> */}
    //   <Testimonials data={landingPageData.Testimonials} />
    //   <Team data={landingPageData.Team} />
    //   <Contact data={landingPageData.Contact} />
    // </div>
  );
};

export default App;
