import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import 'bootstrap/dist/css/bootstrap.css';






export const BlogPage = (props) => {
    const [blogData, setBlogData] = useState({});
    const { id } = useParams();
    useEffect(() => {
        handleCheckData();
    })

    function handleCheckData() {
        if (props?.data !== undefined) {
            setBlogData(props?.data[id])
        }
    }


    return (
        <div id="blog">
            <div className="container mt-3">
                <div className="card">
                    <div className="card-body">
                        <h1 className="text-center"> {blogData?.title}</h1>
                        <hr />
                        <p>{blogData?.desc}</p>

                    </div>
                </div>

            </div>
        </div>

    )
}