import { useState, useEffect } from "react";
import React from "react";
import BlogCard from "./blogCard";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min";

export const Blog = (props) => {
    const [blogs, setBlogs] = useState();
    const [search, setSearchItem] = useState('');

    useEffect(() => {
        console.log(props)
        if (props?.data) {
            setBlogs(props?.data)
        }

    }, [props?.data])

    const handleSearchClick = (e) => {
        const search = e.target.value.toLowerCase();
        const filteredItems = props?.data?.filter((item) => {
            const lowerCase = item?.title.toLowerCase();
            return lowerCase.includes(search)
        });
        setBlogs(filteredItems);
        setSearchItem(search)

        console.log(filteredItems)
    }



    return (
        <div className="container">

            <div className="section-title">
                <h2 className="section-title text-center"> Blog Posts</h2>
            </div>
            <div class="input-group mb-3 text-right" style={{ width: '30%' }}>
                <input type="text" value={search} onChange={handleSearchClick} class="form-control" placeholder="search blogs" aria-label="Username" aria-describedby="basic-addon1" />
                <button className="btn btn-sm btn-primary">Search</button>
            </div>


            <div className="row">
                {blogs?.slice(0, 6)?.map((blog) => (
                    <div key={blog.id} className="card col-md-4 col-xs-12 mb-4">
                        <div className="card-body">
                            <h2 className="text-bold">{blog?.title}</h2>
                            <hr />
                            <p>{blog?.desc.slice(0, 350)}</p>
                            <a className="btn btn-lg btn-primary" href={`blog/${blog?.id - 1}`}>View More</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}