import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Blog } from "./blogs";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import SmoothScroll from "smooth-scroll";




export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

let JsonData = {
    "Header": {
        "title": "We are Law Firm",
        "paragraph": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum."
    },
    "About": {
        "paragraph": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "Why": [
            "Lorem ipsum dolor",
            "Tempor incididunt",
            "Lorem ipsum dolor",
            "Incididunt ut labore"
        ],
        "Why2": [
            "Aliquip ex ea commodo",
            "Lorem ipsum dolor",
            "Exercitation ullamco",
            "Lorem ipsum dolor"
        ]
    },
    "Gallery": [
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/01-large.jpg",
            "smallImage": "img/portfolio/01-small.jpg"
        },
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/02-large.jpg",
            "smallImage": "img/portfolio/02-small.jpg"
        },
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/03-large.jpg",
            "smallImage": "img/portfolio/03-small.jpg"
        },
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/04-large.jpg",
            "smallImage": "img/portfolio/04-small.jpg"
        },
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/05-large.jpg",
            "smallImage": "img/portfolio/05-small.jpg"
        },
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/06-large.jpg",
            "smallImage": "img/portfolio/06-small.jpg"
        },
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/07-large.jpg",
            "smallImage": "img/portfolio/07-small.jpg"
        },
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/08-large.jpg",
            "smallImage": "img/portfolio/08-small.jpg"
        },
        {
            "title": "Project Title",
            "largeImage": "img/portfolio/09-large.jpg",
            "smallImage": "img/portfolio/09-small.jpg"
        }
    ],
    "Services": [
        {
            "icon": "fa fa-wordpress",
            "name": "Lorem ipsum dolor",
            "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
            "icon": "fa fa-cart-arrow-down",
            "name": "Consectetur adipiscing",
            "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
            "icon": "fa fa-cloud-download",
            "name": "Lorem ipsum dolor",
            "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
            "icon": "fa fa-language",
            "name": "Consectetur adipiscing",
            "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
            "icon": "fa fa-plane",
            "name": "Lorem ipsum dolor",
            "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        },
        {
            "icon": "fa fa-pie-chart",
            "name": "Consectetur adipiscing",
            "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
        }
    ],
    "Testimonials": [
        {
            "img": "img/testimonials/01.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "John Doe"
        },
        {
            "img": "img/testimonials/02.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "Johnathan Doe"
        },
        {
            "img": "img/testimonials/03.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "John Doe"
        },
        {
            "img": "img/testimonials/04.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "Johnathan Doe"
        },
        {
            "img": "img/testimonials/05.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "John Doe"
        },
        {
            "img": "img/testimonials/06.jpg",
            "text": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.\"",
            "name": "Johnathan Doe"
        }
    ],
    "Team": [
        {
            "img": "img/team/01.jpg",
            "name": "John Doe",
            "job": "Director"
        },
        {
            "img": "img/team/02.jpg",
            "name": "Mike Doe",
            "job": "Senior Designer"
        },
        {
            "img": "img/team/03.jpg",
            "name": "Jane Doe",
            "job": "Senior Designer"
        },
        {
            "img": "img/team/04.jpg",
            "name": "Karen Doe",
            "job": "Project Manager"
        }
    ],
    "Contact": {
        "address": "4321 California St, San Francisco, CA 12345 ",
        "phone": "+1 123 456 1234",
        "email": "info@company.com",
        "facebook": "fb.com",
        "twitter": "twitter.com",
        "youtube": "youtube.com"
    },
    "Features": [
        {
            "icon": "fa fa-comments-o",
            "title": "Banking & Finance",
            "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
        },
        {
            "icon": "fa fa-briefcase",
            "title": "Corporate & Commercial",
            "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
        },
        {
            "icon": "fa fa-home",
            "title": "Litigation & Arbitration",
            "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
        },
        {
            "icon": "fa fa-magic",
            "title": "Lorem ipsum",
            "text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
        }
    ],
    "Blogs": [
        {
            "id": 1,
            "title": "This is test",
            "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum provident repellat tempora cum quis nam, laborum aliquid saepe magni, vitae, autem hic soluta cupiditate dolorum eius doloremque numquam natus eligendi sequi nobis repudiandae iure incidunt! Excepturi consequatur fuga vero neque facilis eius laudantium aut quis tempore non. Soluta molestiae pariatur nisi molestias unde odio tenetur atque omnis aliquam in reprehenderit saepe libero, quasi veniam, quas laborum animi, ratione quae dignissimos cumque aspernatur. Rem asperiores consequuntur veritatis molestiae, commodi explicabo dignissimos dolorum numquam ullam neque officiis a officia rerum! Non corrupti cum distinctio rerum quis deleniti consequatur sequi, tempora praesentium ipsam."
        },
        {
            "id": 2,
            "title": "Tiger on Roof",
            "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum provident repellat tempora cum quis nam, laborum aliquid saepe magni, vitae, autem hic soluta cupiditate dolorum eius doloremque numquam natus eligendi sequi nobis repudiandae iure incidunt! Excepturi consequatur fuga vero neque facilis eius laudantium aut quis tempore non. Soluta molestiae pariatur nisi molestias unde odio tenetur atque omnis aliquam in reprehenderit saepe libero, quasi veniam, quas laborum animi, ratione quae dignissimos cumque aspernatur. Rem asperiores consequuntur veritatis molestiae, commodi explicabo dignissimos dolorum numquam ullam neque officiis a officia rerum! Non corrupti cum distinctio rerum quis deleniti consequatur sequi, tempora praesentium ipsam."
        },
        {
            "id": 3,
            "title": "Lion is on Hunt",
            "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum provident repellat tempora cum quis nam, laborum aliquid saepe magni, vitae, autem hic soluta cupiditate dolorum eius doloremque numquam natus eligendi sequi nobis repudiandae iure incidunt! Excepturi consequatur fuga vero neque facilis eius laudantium aut quis tempore non. Soluta molestiae pariatur nisi molestias unde odio tenetur atque omnis aliquam in reprehenderit saepe libero, quasi veniam, quas laborum animi, ratione quae dignissimos cumque aspernatur. Rem asperiores consequuntur veritatis molestiae, commodi explicabo dignissimos dolorum numquam ullam neque officiis a officia rerum! Non corrupti cum distinctio rerum quis deleniti consequatur sequi, tempora praesentium ipsam."
        },
        {
            "id": 4,
            "title": "Bitskraft on Sale",
            "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum provident repellat tempora cum quis nam, laborum aliquid saepe magni, vitae, autem hic soluta cupiditate dolorum eius doloremque numquam natus eligendi sequi nobis repudiandae iure incidunt! Excepturi consequatur fuga vero neque facilis eius laudantium aut quis tempore non. Soluta molestiae pariatur nisi molestias unde odio tenetur atque omnis aliquam in reprehenderit saepe libero, quasi veniam, quas laborum animi, ratione quae dignissimos cumque aspernatur. Rem asperiores consequuntur veritatis molestiae, commodi explicabo dignissimos dolorum numquam ullam neque officiis a officia rerum! Non corrupti cum distinctio rerum quis deleniti consequatur sequi, tempora praesentium ipsam."
        },
        {
            "id": 5,
            "title": "Koili Best Performance",
            "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum provident repellat tempora cum quis nam, laborum aliquid saepe magni, vitae, autem hic soluta cupiditate dolorum eius doloremque numquam natus eligendi sequi nobis repudiandae iure incidunt! Excepturi consequatur fuga vero neque facilis eius laudantium aut quis tempore non. Soluta molestiae pariatur nisi molestias unde odio tenetur atque omnis aliquam in reprehenderit saepe libero, quasi veniam, quas laborum animi, ratione quae dignissimos cumque aspernatur. Rem asperiores consequuntur veritatis molestiae, commodi explicabo dignissimos dolorum numquam ullam neque officiis a officia rerum! Non corrupti cum distinctio rerum quis deleniti consequatur sequi, tempora praesentium ipsam."
        },
        {
            "id": 6,
            "title": "Town Hall on Bitskraft",
            "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum provident repellat tempora cum quis nam, laborum aliquid saepe magni, vitae, autem hic soluta cupiditate dolorum eius doloremque numquam natus eligendi sequi nobis repudiandae iure incidunt! Excepturi consequatur fuga vero neque facilis eius laudantium aut quis tempore non. Soluta molestiae pariatur nisi molestias unde odio tenetur atque omnis aliquam in reprehenderit saepe libero, quasi veniam, quas laborum animi, ratione quae dignissimos cumque aspernatur. Rem asperiores consequuntur veritatis molestiae, commodi explicabo dignissimos dolorum numquam ullam neque officiis a officia rerum! Non corrupti cum distinctio rerum quis deleniti consequatur sequi, tempora praesentium ipsam."
        },
        {
            "id": 7,
            "title": "Nice chha",
            "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum provident repellat tempora cum quis nam, laborum aliquid saepe magni, vitae, autem hic soluta cupiditate dolorum eius doloremque numquam natus eligendi sequi nobis repudiandae iure incidunt! Excepturi consequatur fuga vero neque facilis eius laudantium aut quis tempore non. Soluta molestiae pariatur nisi molestias unde odio tenetur atque omnis aliquam in reprehenderit saepe libero, quasi veniam, quas laborum animi, ratione quae dignissimos cumque aspernatur. Rem asperiores consequuntur veritatis molestiae, commodi explicabo dignissimos dolorum numquam ullam neque officiis a officia rerum! Non corrupti cum distinctio rerum quis deleniti consequatur sequi, tempora praesentium ipsam."
        },
    ]
}


export const Homepage = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);
    return (
        <div>
            {/* <Navigation /> */}
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Blog data={landingPageData.Blogs} />
            {/* <Gallery data={landingPageData.Gallery} /> */}
            <Testimonials data={landingPageData.Testimonials} />
            <Team data={landingPageData.Team} />
            <Contact data={landingPageData.Contact} />
        </div>
    )


}